body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}
#root {
  height: 100vh;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Sidebar */
.sidebar {
  width: 250px;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.3s;
}

.sidebar.closed {
  margin-left: -250px;
}
.mr-custom {
  margin-left: 300px;
}
.content {
  transition: margin-left 0.3s;
}

.content .btn {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .sidebar,
  .content {
    margin-left: 0;
  }
}

/* Notes */

.btn-left {
  border-right: 2px solid white !important;

  margin-right: 1px;
}
.btn-right {
  border-left: 2px solid white !important;
}

.Note {
  display: flex;
  flex-direction: column;
  height: 95vh; /* Adjust the height of the note container */
}

.note-title {
  width: 100%;
  padding: 10px;
  font-size: 1.2em;
  border: none;
  border-bottom: 1px solid #ccc; /* A subtle bottom border for the title input */
  box-sizing: border-box; /* Ensures padding doesn't affect overall width */
}

.note-content {
  flex-grow: 1; /* Allows the textarea to fill the remaining space */
  padding: 10px;
  border: none;
  resize: none; /* Disables the resize handle on the textarea */
  box-sizing: border-box;
}

/* Additional styles for placeholder text */
.note-title::placeholder,
.note-content::placeholder {
  color: #aaa;
}

.input-group,
.btn-group,
.btn-group > .btn,
.input-group > .form-control {
  position: inherit !important;
  z-index: auto !important;
}

.closeTimes {
  color: #dc3545;
}
.closeTimes:hover {
  color: #bb2d3b;
  /* background-color: #; */
}

.btn-square {
  border-radius: 0 !important;
}
